import { clone } from '~/helpers/javascript';
import {
  connectChannel,
  addToSchedule,
  getPost,
  getSocialProfile,
  unlinkChannel,
  deleteToSchedule,
  updateSocialProfile,
  socketToSchedule,
  getLastScheduleDate,
  updatePost
} from '../helpers/schedule.services ';

import Toast from '~/helpers/notification';
import debounce from 'lodash.debounce';

const debouncedUpdateSocialProfile = debounce(updateSocialProfile, 5000);

export const useScheduleStore = (set, get) => ({
  // state
  scheduleComponentStates: 'setting', // repeat | set-time | scheduled | published | setting
  daysToPost: [
    { day: 'Sun', active: true, label: 'Every Sunday' },
    { day: 'Mon', active: true, label: 'Every Monday' },
    { day: 'Tue', active: true, label: 'Every Tuesday' },
    { day: 'Wed', active: true, label: 'Every Wednesday' },
    { day: 'Thu', active: true, label: 'Every Thursday' },
    { day: 'Fri', active: true, label: 'Every Friday' },
    { day: 'Sat', active: true, label: 'Every Saturday' }
  ],
  timeToPost: [
    { time: '09:00', active: true },
    { time: '13:00', active: false },
    { time: '18:00', active: false },
    { time: '22:00', active: false }
  ],
  videosToSchedule: [],
  videosPublished: [],
  isLoadingChannel: false,
  channel: {
    isLinked: false,
    thumbnailUrl: '',
    title: ''
  },
  videoToPost: {
    video: {},
    isLoading: false
  },
  videoToDelete: {
    video: {},
    isLoading: false
  },
  isLoadingScheduled: false,
  isLoadingPublished: false,
  IOTScheduled: null,
  publishedPagination: { totalDocuments: 0, totalPages: 0 },

  // actions
  setScheduleComponentStatesAction: (scheduleComponentStates) => set({ scheduleComponentStates }),

  getSocialProfileAction: async () => {
    set({ isLoadingChannel: true });
    const socialProfile = await getSocialProfile(get().user._id);
    let channel = {
      isLinked: false,
      thumbnailUrl: '',
      title: ''
    };
    if (socialProfile && socialProfile.displayNames && socialProfile.displayNames.length !== 0) {
      channel = {
        isLinked: true,
        thumbnailUrl: socialProfile.displayNames[0]?.userImage,
        title: socialProfile.displayNames[0]?.username
      };
    }
    set({ isLoadingChannel: false, channel: channel });

    if (socialProfile.settings)
      set({
        daysToPost: socialProfile.settings.daysToPost,
        timeToPost: socialProfile.settings.timeToPost
      });
  },

  connectChannelAction: async () => {
    if (!get().isLoadingChannel) {
      get().trackingAction({
        event: 'onButtonClick',
        category: 'idea-to-video',
        action: 'connect-channel-schedule'
      });

      set({ isLoadingChannel: true });

      const user = {
        name: get().user.name,
        email: get().user.email,
        userId: get().user._id
      };

      const redirect = `${window.location.origin}/idea-to-video/app`;

      const connect = await connectChannel(user, redirect);
      // console.log('connect_', connect);
      set({ isLoadingChannel: false });
      if (connect.url) {
        localStorage.setItem('ideaToVideoState', JSON.stringify(get()));
        setTimeout(() => {
          window.location.href = connect.url;
        }, 200);
      }
    }
  },

  unlinkChannelAction: async () => {
    if (!get().isLoadingChannel) {
      set({ isLoadingChannel: true });

      const user = {
        userId: get().user._id
      };

      const unlink = await unlinkChannel(user);
      if (unlink && unlink.status === 'success')
        set({
          channel: {
            isLinked: false,
            thumbnailUrl: '',
            title: ''
          }
        });
      set({ isLoadingChannel: false });
    }
  },

  getScheduledAction: async () => {
    set({ isLoadingScheduled: true });
    const scheduled = await getPost(get().user?._id);
    // console.log('scheduled_', scheduled);
    set({ videosToSchedule: scheduled.data, isLoadingScheduled: false });
  },

  getPublishedAction: async (page = 1) => {
    set({ isLoadingPublished: true, publishedPagination: { totalDocuments: 0, totalPages: 0 } });
    const published = await getPost(get().user?._id, page, 6, 'published');
    // console.log('published_', published);
    set({
      videosPublished: published.data,
      publishedPagination: published.meta,
      isLoadingPublished: false
    });
  },

  setDayToPostAction: async (idx) => {
    let _daysToPost = [...get().daysToPost];
    _daysToPost[idx].active = !_daysToPost[idx].active;
    set({ daysToPost: _daysToPost });

    // TODO: validations
    const data = {
      settings: {
        daysToPost: _daysToPost,
        timeToPost: get().timeToPost
      }
    };

    debouncedUpdateSocialProfile(get().user._id, data);
  },

  setTimeToPostAction: async (idx, time) => {
    let _timeToPost = [...get().timeToPost];
    _timeToPost[idx].time = time;
    set({ timeToPost: _timeToPost });

    // TODO: validations
    const data = {
      settings: {
        daysToPost: get().daysToPost,
        timeToPost: _timeToPost
      }
    };

    debouncedUpdateSocialProfile(get().user._id, data);
  },

  setActiveDayToPostAction: async (idx) => {
    let _timeToPost = [...get().timeToPost];
    _timeToPost[idx].active = !_timeToPost[idx].active;
    set({ timeToPost: _timeToPost });

    // TODO: validations
    const data = {
      settings: {
        daysToPost: get().daysToPost,
        timeToPost: _timeToPost
      }
    };

    debouncedUpdateSocialProfile(get().user._id, data);
  },

  addToScheduleAction: async (video) => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'add-to-schedule'
    });

    let _video = clone(video);
    _video.proportion = 1;

    set({
      videoToPost: {
        video: _video,
        isLoading: true
      }
    });
    let lastScheduleDate = new Date();

    const lastPost = await getLastScheduleDate(get().user._id);
    // console.log('lastPost_', lastPost);

    if (lastPost && lastPost.data && lastPost.data.length > 0)
      lastScheduleDate = new Date(lastPost.data[0].publicationMetadata.scheduleDate);
    // console.log('lastScheduleDate_', lastScheduleDate);

    let _data = {
      user: get().user._id,
      video: _video,
      scheduleDate: null,
      tool: 'idea-to-video'
    };

    const addScheduleDate = (_data, lastScheduleDate) => {
      if (!lastScheduleDate || isNaN(lastScheduleDate.getTime())) {
        lastScheduleDate = new Date();
      }

      let currentDate = new Date();
      let startDate = lastScheduleDate > currentDate ? lastScheduleDate : currentDate;

      for (let i = 0; i < get().daysToPost.length; i++) {
        let day = get().daysToPost[(startDate.getDay() + i) % get().daysToPost.length];
        if (day.active) {
          for (let j = 0; j < get().timeToPost.length; j++) {
            let time = get().timeToPost[j];
            if (time.active) {
              let newDate = new Date(startDate.getTime());

              newDate.setDate(newDate.getDate() + i);
              newDate.setHours(parseInt(time.time.split(':')[0]));
              newDate.setMinutes(parseInt(time.time.split(':')[1]));

              if (newDate > startDate) {
                _data.scheduleDate = newDate.toString();
                return _data;
              }
            }
          }
        }
      }

      return _data;
    };

    let scheduledData = addScheduleDate(_data, lastScheduleDate);
    // console.log('scheduledData_', scheduledData);

    const _addToSchedule = await addToSchedule(scheduledData);
    // console.log('_addToSchedule', _addToSchedule);
    if (_addToSchedule && _addToSchedule.result)
      Toast.success('Woohoo! Video added to the post list! 🎉');
    else Toast.error('Oh no! An error occurred while adding the video to the post list. 😔');

    set({
      videoToPost: {
        video: {},
        isLoading: false
      }
    });
  },

  deleteVideoToScheduleAction: async (video, idx) => {
    set({
      videoToDelete: {
        video: video,
        isLoading: true
      }
    });
    const _deleteToSchedule = await deleteToSchedule(
      video.user,
      video.taskid,
      video.publicationMetadata.postId || null
    );
    if (_deleteToSchedule) {
      let _videosToSchedule = [...get().videosToSchedule];

      _videosToSchedule.splice(idx, 1);
      set({ videosToSchedule: _videosToSchedule });

      Toast.success('Video successfully removed from the post list. 🎉');
    } else Toast.error('There was an error removing the video from the post list. 😔');

    set({
      videoToDelete: {
        video: {},
        isLoading: false
      }
    });
  },

  updateScheduleDateAction: async (taskId, scheduleDate) => {
    set({ isLoadingScheduled: true });
    const _updatePost = await updatePost(get().user._id, taskId, scheduleDate);

    if (_updatePost) {
      Toast.success("Great! Your video's publication date has been successfully updated. 🎉");
      get().getScheduledAction();
    } else {
      Toast.error("Oops! There was an issue updating your video's publication date. 😔");
      set({ isLoadingScheduled: true });
    }
  },

  connectSocketToScheduleAction: async () => {
    const iotConnection = await socketToSchedule();

    const { mqtt } = await import('aws-iot-device-sdk-v2');
    await iotConnection.subscribe(
      `/video/schedule/${get().user._id}`,
      mqtt.QoS.AtLeastOnce,
      (topic, payload, dup, qos, retain) => {
        const decoder = new TextDecoder('utf8');
        let message = JSON.parse(decoder.decode(new Uint8Array(payload)));
        // console.log('message_', message);

        let _videosToSchedule = [...get().videosToSchedule];
        _videosToSchedule.map((v) => {
          if (v.taskid === message.taskId) v.status = message.status;
        });

        set({ videosToSchedule: _videosToSchedule });
      }
    );

    set({ IOTScheduled: iotConnection });
  },

  disconnectSocketToScheduleAction: () => {
    if (get().IOTScheduled) get().IOTScheduled.disconnect();
  },

  openScheduleAction: () => {
    if (!get().plan) {
      set({
        premiumModal: { type: 'schedule', show: true }
      });
    }

    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'schedule'
    });
  }
});
